import { lazy } from "react"
import axios from "axios"

const loader = async ({ params, request }) => {
  const { processId } = params
  const searchParams = new URL(request.url).searchParams
  
  const action = searchParams.get('_action') || 'default'
  const selectedCycleId = searchParams.get('selectedCycleId')
  const type = searchParams.get('type')

  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review/overview`, { params: { action, selectedCycleId, type } })
  return result?.data
}

export const JourneyBuilderScheduleReviewOverviewRoute = {
  loader,
  Element: lazy(() => import('./element')),
}
