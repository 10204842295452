import React from 'react'
import axios from 'axios'
import { Outlet } from 'react-router-dom'
import { SoraNavLink } from '@components/link'
import { Typography, unstable_Tooltip as Tooltip } from '@design-system'

export async function loader() {
  const result = await axios.get('/backoffice/reports/index')
  return result.data
}

function Element() {
  return (
    <div className="flex grow gap-6 max-w-full">
      <div className="space-y-3 border-x px-3 h-fit border-x-gray-30">
        <Typography weight="bold" variant="heading-6">Reports</Typography>
        <ul>
          <SidebarOpt url="students/requests" label="Students' Requests" />
          <SidebarOpt url="students/missing/units" label="Student Missing Units" />
          <SidebarOpt url="students/availability" label="Student Availability" />
          <SidebarOpt url="registration-changes" label="Registration Changes" />
          <SidebarOpt url="experiences-roster" label="Experiences Roster" />
          <SidebarOpt url="units-completion" label="Units Completion" />
          <SidebarOpt url="materials" label="Materials" />
        </ul>
      </div>
      <div className="flex flex-col grow max-w-full">
        <Outlet />
      </div>
    </div>
  )
}

interface SidebarOptProps {
  url: string,
  label: string,
}

function SidebarOpt({ url, label }: SidebarOptProps) {
  return (
    <li>
      <Tooltip content={label}>
        <SoraNavLink to={url} className={(active) => `hover:underline text-ellipsis line-clamp-1 ${active ? 'font-bold underline' : ''}`}>
          {label}
        </SoraNavLink>
      </Tooltip>
    </li>
  )
}

export const ReportsRoute = { Element, loader }
