import { lazy } from 'react'
import axios from 'axios'

const loader = async({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/_index`)
  return result?.data
}

export const JourneyBuilderProcessIndexRoute = {
  loader,
  Element: lazy(() => import('./element')),
}
