import { redirect } from 'react-router-dom'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'
import Element from './element'

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/assignments`, { ...serializedFormData, action })
    return action === 'next' ? redirect('../schedule/config') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

export const JourneyBuilderAssignmentsRoute = {
  action,
  Element,
}
