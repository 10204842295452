import { lazy } from 'react'
import axios from 'axios'
import { redirect } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/templates/review`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/templates/review`, { ...serializedFormData, action })
    return (action === 'next') ? redirect('../../assignments/students') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}



export const JourneyBuilderTemplatesReviewRoute = {
  loader,
  action,
  Element: lazy(() => import('./element')),
}
