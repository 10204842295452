import React from 'react'
import { Button, Combobox, useFetcher, Typography, unstable_Tooltip as Tooltip } from '@design-system'
import { TextField } from '@designsystem'
import PropTypes from 'prop-types'

export const StudentAdd = ({ isDark = false, isFull = false }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'
  const hasData = Boolean(fetcher.data?.students)

  const dialogRef = React.useRef(null)
  const dialogContentRef = React.useRef(null)
  React.useEffect(() => {
    const clickOutsideHandler = (event) => {
      if (dialogContentRef.current && !dialogContentRef.current.contains(event.target) && dialogRef.current.contains(event.target)) {
        handleCancel(event)
      }
    }
    document.addEventListener('click', clickOutsideHandler)
    return () => {
      document.removeEventListener('click', clickOutsideHandler)
    }
  }, [dialogContentRef])

  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method: 'post' })
  }

  const OptionalTooltip = ({show, content, children}) => {
    if (show) return (
      <Tooltip content={content}>
        <div>{children}</div>
      </Tooltip>
    )
    return children
  }
  OptionalTooltip.propTypes = {
    show: PropTypes.bool,
    content: PropTypes.string,
    children: PropTypes.node,
  }

  return (
    <>
      <fetcher.Form method="get">
        <input type="hidden" name="_action" value="get_available_students" />
        <OptionalTooltip show={isFull} content="This experience is full">
          <Button variant={isDark ? 'contained' : 'outlined'} color={isDark ? 'dark' : 'soft'} size='sm' loading={isLoading} disabled={isLoading || hasData || isFull}>
            Add student
          </Button>
        </OptionalTooltip>
      </fetcher.Form>
      <div ref={dialogRef} className={`fixed inset-0 h-screen w-screen flex flex-col justify-center items-center bg-black/60 ${hasData ? '' : 'hidden'}`}>
        <div ref={dialogContentRef} className='min-w-[600px] bg-white dark:bg-gray-100 shadow border-2 border-gray-30 dark:border-gray-90 z-50 rounded-lg'>
          <div className='w-full border-b border-gray-30 dark:border-gray-90 p-4'>
            <Typography variant="subheadline" weight="bold">
              Add student
            </Typography>
          </div>
          <fetcher.Form method="post" className='p-2'>
            <input type="hidden" name="_action" value="add_student" />
            <div>
              {(hasData) &&            
                  <div className='flex flex-col space-y-3 p-4'>
                    <Combobox name='student_id' label='Student' placeholder='Select a student' loading={isLoading} search={<Combobox.Search className='pointer-events-auto' autoFocus placeholder="Search a student" />} >
                      {fetcher.data?.students.map(student => {
                        return (
                          <Combobox.Item key={`opt_student_${student.id}`} value={student.id}>
                            {student.name}
                          </Combobox.Item>
                        )
                      })}
                    </Combobox>
                    <TextField as="textarea" name="notes" label="Notes on registration change" placeholder="Add notes..." />
                  </div>
              }
            </div>
            <div className="flex items-center space-x-3 justify-end px-2">
              <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
              <Button type="submit">Save</Button>
            </div>
          </fetcher.Form>  
        </div>
      </div>
    </>
  )
}
StudentAdd.propTypes = {
  isDark: PropTypes.bool,
  isFull: PropTypes.bool,
}
