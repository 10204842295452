import { lazy } from "react"
import { redirect } from "react-router-dom"
import axios from "axios"
import serializeFormData from "@utils/serializeFormData"

const loader = async ({ params, request }) => {
  const { processId } = params
  const searchParams = new URL(request.url).searchParams
  
  const action = searchParams.get('_action') || 'default'
  const expertId = searchParams.get('expert_id')
  const cycleId = searchParams.get('cycle_id')
  const templateId = searchParams.get('template_id')

  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review/expeditions`, { params: { action, expert_id: expertId, cycle_id: cycleId, template_id: templateId } })
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = formData.get('_action')

  if (action === 'cancel') return null

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review/expeditions`, { ...serializedFormData, action })
    return action === 'generate' ? redirect('../../generated/students') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

export const JourneyBuilderScheduleReviewExpeditionsRoute = {
  action,
  loader,
  Element: lazy(() => import('./element')),
}
