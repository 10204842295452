import axios from 'axios'
import { redirect } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { lazy } from 'react'

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/templates/config`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params

  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/templates/config`, { setup_params: serializedFormData })
    return redirect('../review')
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

export const JourneyBuilderTemplatesConfigRoute = {
  loader,
  action,
  Element: lazy(() => import('./element')),
}
