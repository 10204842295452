import React, { useEffect, useRef, useState } from 'react'
import { Form, Outlet, useActionData, useLoaderData, useSubmit } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useThisPageTitle } from '@hooks/usePageTitle'
import SoraLink from '@components/link'
import useDebounce from '@hooks/useDebouce'
import Paginator from '@components/paginator'
import { useToast } from '@hooks/useToast'
import { Button, Icon, Typography } from '@design-system'
import useConfirmModal from '@hooks/useConfirmModal'
import TemplateCard from '@components/template-card'
import capitalize from '@utils/capitalize'

export default function TemplateExpeditionLibraryRoute() {

  useThisPageTitle('Sora Template Library')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const filterFormRef = useRef()

  const loaderData = useLoaderData()

  const subjects = loaderData.templateSubjects
  const abilities = loaderData.abilitiesV2
  const user = loaderData.user
  const createUrl = loaderData.createUrl

  const submit = useSubmit()

  const initialTemplates = loaderData.result || []

  useToast(useActionData())

  useEffect(() => {
    filterFormRef.current && submit(filterFormRef.current)
  }, [debouncedSearchTerm])

  const schoolStageFilter = [
    { id: 'hs', title: 'High School' },
    { id: 'ms', title: 'Middle School' },
  ]

  const [openFilters, setOpenFilters] = useState({
    school_stages: true,
    abilities: true,
    subjects: true,
    templates: true,
  })
  const [showArchived, setShowArchived] = useState(false)
  const [sorting, setSorting] = useState('alphabetical')

  const expeditionTypesFilter = [
    { id: 0, title: 'Expedition', type: 'expedition' },
    { id: 1, title: 'Independent Study', type: 'independent_study' },
    { id: 2, title: 'Activity', type: 'activity' },
    { id: 3, title: 'Math learning Goal', type: 'math_learning_goal' },
    { id: 4, title: 'Language Learning Goal', type: 'language_learning_goal' },
  ]

  useEffect(() => {
    filterFormRef.current && submit(filterFormRef.current)
  }, [showArchived])

  useEffect(() => {
    filterFormRef.current && submit(filterFormRef.current)
  }, [sorting])

  const toggleOpenFilter = (filter) => {
    setOpenFilters((prev) => {
      return {
        ...prev,
        [filter]: !prev[filter],
      }
    })
  }

  const handleFormChange = (e) => {
    const formEl = e.currentTarget
    submit(formEl)
  }

  return (
    <>
      <Outlet />
      <Typography variant="heading-3" weight="bold" className="mb-6">Templates</Typography>
      <div className="space-y-2 min-w-[75rem] pb-5">
        <div className="flex space-y-5 flex-row space-x-5">
          <Form id="filter-form" method="get" className="relative h-full" ref={filterFormRef} onChange={handleFormChange}>
            <input type="hidden" name="search" value={debouncedSearchTerm} />
            <input type="hidden" name="sorting" value={sorting} />
            <aside
              aria-label="filters"
              className="space-y-5"
            >
              <div className='flex gap-2'>
                {user.hasEditTemplateLibraryPermission &&
                  <Button asChild className="grow">
                    <SoraLink to={createUrl}>
                      <Icon name="plus" /> Add Template
                    </SoraLink>
                  </Button>
                }
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setShowArchived((prevState) => !prevState)}
                  className="grow"
                >
                  {showArchived ? 'Show regular templates' : 'Archived Templates'}
                </Button>
              </div>
              <input type="hidden" name="archived" value={showArchived} />
              <section className="bg-white dark:bg-gray-95 border flex flex-col h-full w-xs lg:w-sm border-gray-10 rounded-3xl">
                <button
                  type="button"
                  onClick={() => toggleOpenFilter('school_stages')}
                  className="flex flex-row justify-between items-center rounded-3xl bg-black text-white p-2 pl-4 w-full"
                >
                  <h3>School Stages</h3>
                  <div className="bg-white rounded-full h-8 w-8 leading-8 text-black">
                    {openFilters.school_stages ? '▼' : '▲'}
                  </div>
                </button>
                <div className={`flex flex-col space-y-2 p-4 overflow-hidden transition-all ${!openFilters.school_stages ? 'h-0 py-0 opacity-0' : ''}`}>
                  {
                    schoolStageFilter.map(({ id, title }, index) => {
                      return (
                        <label key={id} >
                          <input
                            name={`school_stages[${index}]`}
                            value={id}
                            type="checkbox"
                            defaultChecked={true}
                            className="border-blue-60 mr-1 cursor-pointer text-black"
                            data-cy="ability-checkbox"
                          />
                          {title}
                        </label>
                      )
                    })}
                </div>
              </section>
              <section className="bg-white dark:bg-gray-95 border flex flex-col h-full w-xs lg:w-sm border-gray-10 rounded-3xl">
                <button
                  type="button"
                  onClick={() => toggleOpenFilter('templates')}
                  className="flex flex-row justify-between items-center rounded-3xl bg-black text-white p-2 pl-4 w-full"
                >
                  <h3>Template Types</h3>
                  <div className="bg-white rounded-full h-8 w-8 leading-8 text-black">
                    {openFilters.templates ? '▼' : '▲'}
                  </div>
                </button>
                <div className={`flex flex-col space-y-2 p-4 overflow-hidden transition-all ${!openFilters.templates ? 'h-0 py-0 opacity-0' : ''}`}>
                  {expeditionTypesFilter.map(({ id, title, type }, index) => {
                    return (
                      <label key={id} hidden={!openFilters.templates}>
                        <input
                          name={`templates[${index}]`}
                          value={type}
                          type="checkbox"
                          defaultChecked={false}
                          className="border-blue-60 mr-1 cursor-pointer text-black"
                          data-cy="ability-checkbox"
                        />
                        {title}
                      </label>
                    )
                  })}
                </div>
              </section>

              <section className="bg-white dark:bg-gray-95 border flex flex-col h-full w-xs lg:w-sm border-gray-10 rounded-3xl">
                <button
                  type="button"
                  onClick={() => toggleOpenFilter('subjects')}
                  className="flex flex-row justify-between items-center rounded-3xl bg-black text-white p-2 pl-4 w-full"
                >
                  <h3>Subjects</h3>
                  <div className="bg-white rounded-full h-8 w-8 leading-8 text-black">
                    {openFilters.subjects ? '▼' : '▲'}
                  </div>
                </button>
                <ul className={`flex flex-col space-y-2 p-4 overflow-hidden transition-all ${!openFilters.subjects ? 'h-0 py-0 opacity-0' : ''}`}>
                  {
                    subjects.map((subject) => {
                      const filterKey = `subjects/${subject.id}`
                      const isOpen = openFilters[filterKey]
                      return (
                        <li key={subject.id} hidden={!openFilters.subjects}>
                          <div className="flex items-center space-x-1 mb-2">
                            <input
                              type="checkbox"
                              className="text-blue-60 border-blue-60"
                              data-cy="subject-checkbox"
                              defaultValue={false}
                              onChange={e => {
                                e.target.closest('li').querySelectorAll('input').forEach(el => el.checked = e.target.checked)
                                return e
                              }}
                            />
                            <button
                              type="button"
                              className="hover:underline"
                              onClick={() => toggleOpenFilter(filterKey)}
                            >
                              {subject.title} {isOpen ? '▼' : '▲'}
                            </button>
                          </div>
                          <ul className="pl-4 text-black space-y-2">
                            {subject.topics.map((topic, i) => {
                              return (
                                <li key={topic.id} hidden={!isOpen}>
                                  <label className="flex items-center space-x-1 text-xs">
                                    <input
                                      type="checkbox"
                                      className="text-blue-60 border-blue-60"
                                      name={`topics[]`}
                                      value={topic.id}
                                      defaultChecked={false}
                                    />
                                    <span>
                                      {topic.title} (
                                      {topic.activities_count || 0})
                                    </span>
                                  </label>
                                </li>
                              )
                            })}
                          </ul>
                        </li>
                      )
                    })}
                </ul>
              </section>
              <section className="bg-white dark:bg-gray-95 border flex flex-col h-full w-xs lg:w-sm border-gray-10 rounded-3xl">
                <button
                  type="button"
                  onClick={() => toggleOpenFilter('abilities')}
                  className="flex flex-row justify-between items-center rounded-3xl bg-black text-white p-2 pl-4 w-full"
                >
                  <h3>Abilities</h3>
                  <div className="bg-white rounded-full h-8 w-8 leading-8 text-black">
                    {openFilters.abilities ? '▼' : '▲'}
                  </div>
                </button>
                <div className={`flex flex-col space-y-2 p-4 overflow-hidden transition-all ${!openFilters.abilities ? 'h-0 py-0 opacity-0' : ''}`}>
                  {
                    abilities.map(({ id, title }, i) => {
                      return (
                        <label key={id} hidden={!openFilters.abilities}>
                          <input
                            name={`abilities[${i}]`}
                            value={id}
                            type="checkbox"
                            defaultChecked={false}
                            className="border-blue-60 mr-1 cursor-pointer text-black"
                            data-cy="ability-checkbox"
                          />
                          {title}
                        </label>
                      )
                    })}
                </div>
              </section>
            </aside>
          </Form>
          <section className="flex flex-col space-y-5 w-full">
            <div className="flex">
              <input type="text"
                placeholder="Filter by creator name, template title, description, units or abilities..."
                className="rounded-md grow mr-6"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                aria-label="Creator and Title Search" />
              <div>
                <strong className="mr-2">Sort by</strong>
                <select className="w-40 rounded text-black" onChange={e => setSorting(e.target.value)}>
                  <option value="alphabetical">Alphabetical</option>
                  <option value="newest">Newest</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-5">
              {initialTemplates.length > 0 ? (
                initialTemplates.map((template) => (
                  <CardWrapper
                    key={template.id}
                    user={user}
                    template={template}
                  />
                ))
              ) : (
                <span>
                  It looks like you do not have templates yet. Click the
                  button above to create one!
                </span>
              )}
            </div>
            <div className="flex justify-center">
              <Paginator
                formId="filter-form"
                totalPages={loaderData.total_pages}
                currentPage={loaderData.current_page}
              />
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

function CardWrapper({ user, template }) {
  const submit = useSubmit()
  const confirm = useConfirmModal()

  const handleDeleteSubmit = async e => {
    e.preventDefault()
    const formEl = e.currentTarget
    const confirmed = await confirm(`Are you sure you want to delete ${template.title}?`)
    if (confirmed) {
      submit(formEl)
    }
  }

  return (
    <TemplateCard>
      <TemplateCard.Header
        title={`[${template.school_stage.toUpperCase()}] ${template.title}`}
        subtitle={`created by: ${template.creator_name}`}
        tags={(
          <div className="space-x-2">
            {template.is_deleted === true && <small className='rounded py-1 px-2 bg-yellow-40 text-white font-bold text-xs whitespace-nowrap'>Archived</small>}
            {template.is_draft === true && <small className='rounded py-1 px-2 bg-gray-70 text-white font-bold text-xs whitespace-nowrap'>Draft</small>}
            <small className='rounded py-1 px-2 bg-cyan-40 text-white font-bold text-xs whitespace-nowrap'>{template.type.split('_').filter(word => word !== 'template').map(capitalize).join(' ')}</small>
          </div>
        )}>
        {template.editableByUser &&
          <TemplateCard.Popover>
            <SoraLink
              to={template.editUrl}
            >
              <TemplateCard.PopoverItem title='edit template' label='Edit' />
            </SoraLink>
            {!template.is_deleted && (
              <Form method='post' onSubmit={handleDeleteSubmit}>
                <input type='hidden' name='_action' value='deleteTemplate' />
                <input type='hidden' name='template_id' value={template.id} />
                <button>
                  <TemplateCard.PopoverItem title='Archive template' label='Archive' />
                </button>
              </Form>
            )}
            {template.is_deleted && (
              <Form method='post'>
                <input type='hidden' name='_action' value='unarchiveTemplate' />
                <input type='hidden' name='template_id' value={template.id} />
                <button>
                  <TemplateCard.PopoverItem title='Unarchive template' label='Unarchive' />
                </button>
              </Form>
            )}
          </TemplateCard.Popover>
        }
      </TemplateCard.Header>
      <TemplateCard.Body
        title={template.title}
        description={template.rte_description}
        units={template.units}
        abilities={template.abilities}
        descriptionAction={() => window.open(template.previewUrl, '_blank')} />
      <TemplateCard.Footer>
        {template.is_study_approved !== false ?
          (
            <div className="flex flex-row space-x-2 items-center">
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  window.open(template.previewUrl, '_blank')
                }}
              >
                View
              </Button>
              {user.role === 'admin' && (
                <Button title="Pick Template" asChild>
                  <SoraLink to={`/template/${template.id}/use-picked-template`}>
                    Pick Template
                  </SoraLink>
                </Button>
              )}
            </div>
          ) : !template.is_deleted && (
            <div className="flex flex-row space-x-2 items-center">
              <Form method="post" onSubmit={handleDeleteSubmit}>
                <input type="hidden" name="_action" value="deleteTemplate" />
                <input type="hidden" name="template_id" value={template.id} />
                <Button
                  type="submit"
                  color="danger"
                  title='Delete template'
                >
                  Decline
                </Button>
              </Form>
              <Form method="post">
                <input type="hidden" name="_action" value="approveStudy" />
                <input type="hidden" name="template_id" value={template.id} />
                <Button
                  type="submit"
                  title="Approve template"
                >
                  Approve
                </Button>
              </Form>
            </div>
          )}
      </TemplateCard.Footer>
    </TemplateCard>
  )
}

CardWrapper.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    previewUrl: PropTypes.string,
    school_stage: PropTypes.string,
    is_redesigned_template: PropTypes.bool,
    creator_name: PropTypes.string,
    creator_id: PropTypes.number,
    rte_description: PropTypes.any,
    is_deleted: PropTypes.bool,
    is_draft: PropTypes.bool,
    type: PropTypes.string,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
      })
    ),
    abilities: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
      })
    ),
    is_study_approved: PropTypes.bool,
    editUrl: PropTypes.string,
    editableByUser: PropTypes.bool,
  }),
  user: PropTypes.shape({
    beta: PropTypes.bool,
    role: PropTypes.string,
    id: PropTypes.number,
    hasEditTemplateLibraryPermission: PropTypes.bool,
  }),
  onOpenModal: PropTypes.func,
  onApproveStudy: PropTypes.func,
}
