import { lazy } from 'react'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'

const loader = async ({ params, request }) => {
  const { processId } = params
  const searchParams = new URL(request.url).searchParams
  const action =  searchParams.get('_action') || 'default'
  const assignmentId =  searchParams.get('assignment_id')

  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/assignments/expeditions`, { params: { action, assignment_id: assignmentId }})

  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/assignments/expeditions`, { ...serializedFormData, action })
    return null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

export const JourneyBuilderAssignmentsExpeditionsRoute = {
  loader,
  action,
  Element: lazy(() => import('./element')),
}
