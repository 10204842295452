import React from 'react'
import { redirect } from 'react-router-dom'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/students`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/students`, { ...serializedFormData, action })
    return action === 'next' ? redirect('../templates/config') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}



export const JourneyBuilderStudentsRoute = {
  loader,
  action,
  Element: React.lazy(() => import('./element')),
}
