import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography } from '@design-system'

const JourneyScheduleMetrics = ({ metrics }) => {
  return (
    <div className="flex flex-row gap-3">
      <Card>
        <Card.Content className="flex flex-col p-3 gap-2 ">
          <Typography variant="callout">Allocation</Typography>
          <Typography variant="callout" weight="bold">{metrics.allocation}</Typography>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content className="flex flex-col p-3 gap-2">
          <Typography variant="callout">Template Request Hits</Typography>
          <div className="flex flex-col gap-2">
            {metrics.request_hits_per_cycle.map((rh, idx) => (
              <Typography key={`metric_request_hit_${idx}`} variant="callout" weight="bold">{rh}</Typography>
            ))}
          </div>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content className="flex flex-col p-3 gap-2">
          <Typography variant="callout">Unique students with 1+ request list hits</Typography>
          <div className="flex flex-col gap-2">
            {metrics.student_request_hits_plus_one_per_cycle.map((rh, idx) => (
              <Typography key={`metric_request_hit_${idx}`} variant="callout" weight="bold">{rh}</Typography>
            ))}
          </div>
        </Card.Content>
      </Card>
    </div>
  )
}
JourneyScheduleMetrics.propTypes = {
  metrics: PropTypes.object.isRequired,
}

export default JourneyScheduleMetrics
