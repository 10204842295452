export const EXPEDITION_TYPES = [
  { value: 'expedition', label: 'Expedition' },
  { value: 'track', label: 'Track' },
  { value: 'coworking', label: 'Coworking' },
  { value: 'learning_goal', label: 'Learning Goal' },
  { value: 'math_learning_goal', label: 'Math Learning Goal' },
  { value: 'language_learning_goal', label: 'Language Learning Goal' },
  { value: 'independent_study', label: 'Independent Study' },
  { value: 'activity', label: 'Activity' },
]

export const EXPEDITION_CATEGORIES = [
  { value: 'stem', label: 'STEM' },
  { value: 'humanities', label: 'Humanities' },
  { value: 'electives', label: 'Elective' },
]

// TODO - this can be optimized by iterating over only the specific days of the week we're looking for
export const generateSessionTimes = ({ sessionTimes, cycleStart, desiredSessions }) => {
  const sessions = []
  const pushedSessions = []
  let day = cycleStart
  const infoOfSessionTimes = sessionTimes.map((sessionTimes) => ({ weekDay: sessionTimes.getDay(), hour: sessionTimes.getHours(), minute: sessionTimes.getMinutes() }))

  const cycleStartSessionTime = infoOfSessionTimes.find(({ weekDay }) => weekDay === day.getDay())
  if (day.getHours() > cycleStartSessionTime?.hour || (day.getHours() === cycleStartSessionTime?.hour && day.getMinutes() > cycleStartSessionTime?.minute)) {
    day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)
  }
  
  while (sessions.length < desiredSessions) {
    const isSameWeekDay = infoOfSessionTimes.some(({ weekDay }) => weekDay === day.getDay())
    if (isSameWeekDay) {
      sessions.push(day)
    }
    day = new Date(day.getTime() + 86400000)
  }

  const parsedSessions = sessions.map((session) => {
    const { hour, minute } = infoOfSessionTimes.find(({ weekDay }) => weekDay === session.getDay())
    session.setHours(hour)
    session.setMinutes(minute)
    return session
  }).sort((a, b) => a - b)

  return {
    generatedSessionsTimesArray: parsedSessions,
    pushedSessions,
  }
}

export function hasDST(date = new Date()) {
  const january = new Date(
    date.getFullYear(),
    0,
    1,
  ).getTimezoneOffset()
  const july = new Date(
    date.getFullYear(),
    6,
    1,
  ).getTimezoneOffset()

  return Math.max(january, july) !== date.getTimezoneOffset()
}
