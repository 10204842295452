import React from 'react'
import axios from 'axios'
import { useLoaderData, ActionFunctionArgs } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { Button, Dialog, Icon, Typography, useFetcher } from '@design-system'
import { TextField } from '@designsystem'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

interface LoaderData {
  title: string
  exemption: {
    exemptionId?: number
    course: string
    school: string
    credit: string
    grade: string
    year: string
    units: {
      unit_id: number
      title: string
      checked: boolean
    }[]
  }
  creditOptions: number[]
  gradeOptions: string[]
  yearOptions: number[]
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}/${params.subjectId}/exemption/${params.topicId}?${searchParams.toString()}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}/${params.subjectId}/exemption/${params.topicId}`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

function Element() {
  const { title, exemption, creditOptions, gradeOptions, yearOptions } = useLoaderData() as LoaderData
  const fetcher = useFetcher()
  const formErrors = fetcher.data?.errors || []
  const navigate = useNavigateWithCycle()
  const handleClose = () => navigate(-1)
  return (

    <Dialog open onClose={handleClose}>
      <Dialog.Content>
        <fetcher.Form method="post">
          <Dialog.Header>
            <Dialog.Title>{title}</Dialog.Title>
            <Dialog.Close />
          </Dialog.Header>
          <Dialog.Body>
            <div className="grid grid-cols-3 gap-6">
              <input type="hidden" name="exemptionId" value={exemption.exemptionId} />
              <TextField
                defaultValue={exemption.course}
                className="col-span-full"
                name="course"
                label="Transferred Course Name"
                placeholder="Add course name"
                error={formErrors.find(err => err.fieldName === 'course')?.message}
              />
              <TextField
                defaultValue={exemption.school}
                className="col-span-full"
                name="school"
                label="School Name"
                placeholder="Add school name"
                error={formErrors.find(err => err.fieldName === 'school')?.message}
              />
              <TextField
                defaultValue={exemption.credit}
                name="credit"
                label="Credits"
                as="select"
                error={formErrors.find(err => err.fieldName === 'credit')?.message}
              >
                <option value="">Select</option>
                {creditOptions.map(grade => (
                  <option key={grade} value={grade}>{grade}</option>
                ))}
              </TextField>
              <TextField
                defaultValue={exemption.grade}
                name="grade"
                label="Grade"
                as="select"
                error={formErrors.find(err => err.fieldName === 'grade')?.message}
              >
                <option value="">Select</option>
                {gradeOptions.map(grade => (
                  <option key={grade} value={grade}>{grade}</option>
                ))}
              </TextField>
              <TextField
                defaultValue={exemption.year}
                name="year"
                label="Year"
                as="select"
                error={formErrors.find(err => err.fieldName === 'year')?.message}
              >
                <option value="">Select</option>
                {yearOptions.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </TextField>
              <Typography variant="callout" weight="bold" className="col-span-full">Exempted Units</Typography>
              <div className="col-span-full flex flex-col gap-4">
                {exemption.units.map(({ unit_id, title, checked }) => (
                  <div key={unit_id} className="flex items-center gap-2">
                    <input type="hidden" name={`units[${unit_id}][id]`} defaultValue={unit_id} className="w-6 h-6 rounded-sm" />
                    <input id={`unit_${unit_id}`} type="checkbox" name={`units[${unit_id}][checked]`} defaultChecked={checked} className="w-6 h-6 rounded-sm" />
                    <label htmlFor={`unit_${unit_id}`}>{title}</label>
                  </div>
                ))}
                {formErrors.find(err => err.fieldName === 'unitIds') && (
                  <Typography variant="footnote" color="danger" className="flex items-center gap-1">
                    <Icon size="2xs" name="alert-triangle-filled" />
                    <span>
                      {formErrors.find(err => err.fieldName === 'unitIds').message}
                    </span>
                  </Typography>
                )}
              </div>
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            {!exemption.exemptionId && (
              <Button type="submit" name="_action" value="delete" variant="outlined" size="lg" color="danger">
                Delete Exemption
              </Button>
            )}
            <div className="hidden lg:flex grow" />
            <Dialog.Close asChild>
              <Button variant="outlined" size="lg">
                Cancel
              </Button>
            </Dialog.Close>
            <Button type="submit" size="lg">Save</Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog >
  )
}

export const EmployeeStudentProgramSubjectExemptionRoute = {
  Element,
  loader,
  action
}


