import React from 'react'
import { Form, Outlet, useNavigate, useNavigation } from 'react-router-dom'
import { Button, Tabs } from '@design-system'

const Element = () => {
  const navigate = useNavigate()
  const navigation = useNavigation()

  const isFormSubmitting = navigation.state !== 'idle'

  const backAction = () => {
    navigate('../templates/review')
  }

  const tabs = [
    { label: 'Students', path: './students' },
    { label: 'Expeditions', path: './expeditions' },
  ]
  
  return (
    <>
      <Tabs className='pl-2'>
        {tabs.map(t => (
          <Tabs.Item key={t.label} to={t.path} title={t.label} />
        ))}
      </Tabs>
      <div className="flex-auto relative">
        <div className='text-sm space-y-4 px-2 overflow-y-scroll absolute top-0 bottom-20 w-full'>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </React.Suspense>
        </div>
      </div>
      <div className='flex flex-row justify-end align-middle p-4 space-x-3 absolute bottom-0 left-0 right-0 rounded-xl border border-gray-30 bg-white'>
        <Button type='button' onClick={backAction} disabled={isFormSubmitting} variant='outlined'>Back</Button>
        <Form method="post" id="journey-builder-form">
          <input type="hidden" name="_action" value="next" />
          <Button type='submit' disabled={isFormSubmitting} loading={isFormSubmitting}>Next</Button>
        </Form>
      </div>
    </>
  )
}

export default Element
