import React, { useState } from 'react'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import { faChevronLeft, faChevronRight, faPrint, faTools } from '@fortawesome/free-solid-svg-icons'
import { Switch, Popover } from '@headlessui/react'
import useCurrentUser from '@hooks/useCurrentUser'
import useUserPermissions from '@hooks/useUserPermissions'
import { Button } from '@designsystem'
import { pdfjs as ReactPdf, Document, Page } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'

ReactPdf.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

export function StudentTranscriptRoute() {
  const currentUser = useCurrentUser()
  const { hasPermission } = useUserPermissions()
  const [searchParams, setSearchParams] = useSearchParams()

  const [numOfPages, setNumOfPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  const { filename, transcriptFile } = useLoaderData()

  const handleChangeParams = (value, paramName) => {
    setSearchParams((sp) => {
      if (!value) {
        sp.delete(paramName)
      } else {
        sp.set(paramName, value)
      }

      return sp
    })
  }

  const handleDownloadClick = async () => {
    const url = window.URL.createObjectURL(new Blob([transcriptFile]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()

    // Clean up and revoke the URL object
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const isAdmin = currentUser?.role === 'admin'
  const isFinal = searchParams.get('isFinal') === 'true'
  const isOfficial = searchParams.get('isOfficial') === 'true'
  const showGradDate = searchParams.get('showGraduationDate') === 'true'

  return (
    <div className="flex gap-8 justify-center py-3">
      <div className="flex flex-col gap-2">
        {
          (isAdmin || hasPermission('generate_official_transcript')) &&
          <Popover className="relative">
            <Popover.Button className="focus:outline-none w-full">
              <Button display="block" startIcon={faTools} />
            </Popover.Button>
            <Popover.Panel className="flex flex-col absolute z-10 bg-white border-2 shadow-lg rounded-lg p-4 whitespace-nowrap gap-4">
              <div className="flex items-center gap-2 text-sm">
                <p>Show Graduation Date</p>
                <Switch
                  checked={showGradDate}
                  onChange={(v) => handleChangeParams(v, 'showGraduationDate')}
                  className={`${showGradDate ? 'bg-blue-60' : 'bg-gray-20'} relative inline-flex items-center h-5 rounded-full w-10`}
                >
                  <span className={`${showGradDate ? 'translate-x-6' : 'translate-x-1'} inline-block w-3 h-3 transform bg-white rounded-full`} />
                </Switch>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <p>Final</p>
                <Switch
                  checked={isFinal}
                  onChange={(v) => handleChangeParams(v, 'isFinal')}
                  className={`${isFinal ? 'bg-blue-60' : 'bg-gray-20'} relative inline-flex items-center h-5 rounded-full w-10`}
                >
                  <span className={`${isFinal ? 'translate-x-6' : 'translate-x-1'} inline-block w-3 h-3 transform bg-white rounded-full`} />
                </Switch>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <p>Official</p>
                <Switch
                  checked={isOfficial}
                  onChange={(v) => handleChangeParams(v, 'isOfficial')}
                  className={`${isOfficial ? 'bg-blue-60' : 'bg-gray-20'} relative inline-flex items-center h-5 rounded-full w-10`}
                >
                  <span className={`${isOfficial ? 'translate-x-6' : 'translate-x-1'} inline-block w-3 h-3 transform bg-white rounded-full`} />
                </Switch>
              </div>
            </Popover.Panel>
          </Popover>
        }
        <Button
          display="block"
          color="cta"
          startIcon={faPrint}
          onClick={handleDownloadClick}>
        </Button>
        <div className="flex flex-col gap-1">
          <div className="flex mx-auto items-center gap-1">
            <Button
              tooltip="Previous Page"
              startIcon={faChevronLeft}
              variant="outlined"
              size="xs"
              onClick={() => setCurrentPage(cp => Math.max(1, cp - 1))}
              disabled={currentPage === 1}
            />
            <Button
              tooltip="Next Page"
              startIcon={faChevronRight}
              variant="outlined"
              size="xs"
              onClick={() => setCurrentPage(cp => Math.min(numOfPages, cp + 1))}
              disabled={currentPage === numOfPages}
            />
          </div>
          <p className="mx-auto text-sm">Page {currentPage}</p>
        </div>
      </div>
      <div className="p-px border shadow-lg">
        <Document file={transcriptFile} onLoadSuccess={(p) => { setNumOfPages(p.numPages) }}>
          <Page pageNumber={currentPage} />
        </Document>
      </div>
    </div>
  )
}
