import React from 'react'
import axios from 'axios'
import { useLoaderData, ActionFunctionArgs, useParams, Outlet, redirect, Form, useSubmit, useNavigate } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { Alert, Button, DropdownMenu, Icon, useFetcher } from '@design-system'
import SoraLink from '@components/link'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

interface LoaderData {
  schoolStages: {
    value: string
    label: string
  }[]
  alert?: {
    variant?: 'notice'
    title: string
    description?: string
    action?: string
    label?: string
  },
  subjects: {
    id: number
    title: string
    creditDiff: number
  }[]
}

export async function loader({ params }) {
  const { data } = await axios.get(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}`)
  if (!params.subjectId) {
    const subjectId = data.subjects[0].id
    return redirect(`/employee/students/${params.student_id}/program/${params.schoolStage}/${subjectId}`)
  }
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

function Element() {
  const { schoolStages, alert, subjects } = useLoaderData() as LoaderData
  const navigate = useNavigateWithCycle()
  const { student_id: studentId, schoolStage, subjectId } = useParams()
  const fetcher = useFetcher()
  const handleChange = (value) => {
    navigate(`/employee/students/${studentId}/program/${value}`)
  }
  return (
    <div className="space-y-8">
      {schoolStages.length > 1 && (
        <div className="-ml-5">
          <DropdownMenu>
            <DropdownMenu.Trigger asChild>
              <Button variant="ghost">
                {schoolStages.find(st => st.value === schoolStage)?.label}
                <Icon name="chevron-down" />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end">
              <DropdownMenu.RadioGroup value={schoolStage} onValueChange={handleChange}>
                {schoolStages.map(item => (
                  <DropdownMenu.RadioItem key={item.value} value={item.value}>
                    {item.label}
                  </DropdownMenu.RadioItem>
                ))}
              </DropdownMenu.RadioGroup>
            </DropdownMenu.Content>
          </DropdownMenu>
        </div>
      )}
      {alert && (
        <Alert variant={alert.variant}>
          <Alert.Title>{alert.title}</Alert.Title>
          {alert.description && (
            <Alert.Description>{alert.description}</Alert.Description>
          )}
          {alert.action && (
            <Alert.Actions>
              <fetcher.Form method="post">
                <input type="hidden" name="_action" value={alert.action} />
                <Button size='xs' type="submit">
                  {alert.label}
                </Button>
              </fetcher.Form>
            </Alert.Actions>
          )}
        </Alert>
      )}
      <nav className="flex gap-2 flex-wrap">
        {subjects.map(subject => (
          <Button key={subject.id} size="xs" color={String(subject.id) === String(subjectId) ? "dark" : "soft"} asChild>
            <SoraLink to={`../program/${schoolStage}/${subject.id}`}>
              {subject.title}
              {subject.creditDiff < 0 && <Icon name="alert-triangle" size="xs" />}
            </SoraLink>
          </Button>
        ))}
      </nav>
      <Outlet />
    </div>
  )
}

export const EmployeeStudentProgramRoute = {
  Element,
  loader,
  action
}


